import React from "react";
import ImageGallery from 'react-image-gallery';
import testImage from '../assets/images/test.jpg'
import testImage2 from '../assets/images/products/Corian/0.jpg'
import {  backgroundImages } from "../util/imageImporter";




export default function MainGallery() {
    const images = [
        {
            original: 'https://picsum.photos/id/1018/1000/600/',
            thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: '../assets/images/hanex/caramel-drizzle_mid-size_rgb.jpg',
            thumbnail: '../assets/images/hanex/caramel-drizzle_mid-size_rgb.jpg',
        },
        {
            original: testImage2,
            thumbnail: testImage2,
        },
        {
            original: testImage2,
            thumbnail: testImage2,
        },
        {
            original: testImage,
            thumbnail: testImage,
        },
    ];

    const galleryImages = backgroundImages.map(images =>(
        {
            original:images, thumbnail:images,
        }
    ))

    return (

        <section className="bg-gray-100 text-gray-800">
            <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                    <h2 className="text-3xl font-serif font-bold tracking-tight text-center sm:text-5xl text-gray-900">Stylish & Durable Countertop Solutions</h2>
                    <p className="max-w-3xl mx-auto mt-4 mb-2 text-xl text-center text-gray-600">At VisCotech we strive to give you the quality & service in the solid surface industry.</p>
                    <section className="py-6 bg-gray-100 text-gray-900">
                        <ImageGallery items={galleryImages} />
                        {/* <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">


                            <img src="/assets/images/Corian-Ash-Concrete-Bathroom.jpg" alt="" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-3 md:row-start-1 bg-gray-500 aspect-square" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/ezgif.com-gif-maker.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/DSP_Corian_Photograph_KBIS_Meredith_Design_Idea_Center_2008_007.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/DSP_Corian_Photograph_KBIS_Meredith_Design_Idea_Center_2008_010.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/Corian-Resilience-Technology-Stratus.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/Tumbleweed.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/Rain-Cloud.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/Poliform-Varenna_foto-8-0013ok-RID2-copy.jpg" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="/assets/images/evening-prima-d.jpg" />
                            <img src="/assets/images/Corian-Cosmos-Prima-Bathroom-Close-Up-Shelf-e1575396454535.jpg" alt="" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
                        </div> */}
                    </section>
                    <div className="flex flex-wrap justify-center">
                    </div>
                </div>

                <div>
                </div>

            </div>
        </section>


    )
}