import './App.css';
import Home from './pages/HomePage';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import NavBar from './components/NavbarComponent';
import Footer from './components/FooterComponent';
import About from './pages/AboutUs';
import Contact from './pages/ContactUs';
import GalleryPage from './pages/GalleryPage';
import ProductPage from './pages/ProductPage';

function App() {
  const isTrue = true;
  return (
    <div>
      <HashRouter>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Product' element={<ProductPage />} />
          <Route path='/Gallery' element={<GalleryPage />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
        <Footer />
      </HashRouter>

    </div>
  );
}

export default App;
