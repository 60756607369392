import '../App.css';
import ContactForm from '../components/ContactFormComponent';
import Footer from '../components/FooterComponent';
import Hero from '../components/HeroComponent';
import Navbar from '../components/NavbarComponent';
import Steps from '../components/StepsComponent';
import Testimonials from '../components/TestimonialsComponent';
import Gallery from '../components/GalleryComponent';
import MainFeature from '../components/MainFeatureComponent';
import { NavLink } from 'react-router-dom';

function About() {
    const isTrue = true;
    document.title = "About Us"
    return (
        <div>


            <div className='pt-20'>
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <div className="flex flex-col lg:flex-row justify-between gap-8">
                        <div className="w-full lg:w-5/12 flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl  font-serif font-bold leading-9 text-gray-800 pb-4">Experience and Excellence: 40 Years in the Industry</h1>
                            <p className="font-normal text-base leading-6 text-gray-600 ">With 40 years of experience in the industry and a long history of successful projects, we are a company you can trust for all your countertop needs. Our team of experts has a wealth of knowledge and expertise, and we're proud to have partnered with some of the biggest names in the business. From hotels and restaurants to famous building and construction companies, we've been able to showcase our skills and abilities on some of the most high-profile projects. Our awards and recognition are proof of the quality of our work and our team's dedication to delivering the best results. We are committed to providing our clients with the highest level of service, and we'd love to hear from you.<NavLink to="/Contact">  <span className="underline text-custom-burgundy transition ease-in-out hover:text-red-600">  Contact us</span></NavLink> to learn more about how we can help with your next project.</p>
                        </div>
                        <div className="w-full lg:w-8/12 ">
                            <img className="w-full h-full bg-contain rounded-xl shadow-lg" src="/assets/images/evening-prima-d.jpg" alt="A group of People" />
                        </div>
                    </div>

                    <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                        <div className="w-full lg:w-5/12 flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-serif font-bold leading-9 text-gray-800 pb-4">Experience Quality and Service in Action: A Look at Our Past Projects</h1>
                            <p className="font-normal text-base leading-6 text-gray-600 ">At VisCotech, we pride ourselves on providing top-notch quality and service in the solid surface industry. Our work can be seen in a variety of commercial and residential venues, from Pearson International Airport and fast-food chains like McDonald's and Burger King, to AMC Theaters, Casino Rama and major banks like TD and CIBC. We have also worked on many healthcare facilities like Credit Valley Hospital, Trillium Hospital, Centenary Hospital and Sunnybrook Hospital. We have also worked on prestigious hotels like The Royal York Hotel, and on major sports and cultural venues like Toronto's Air Canada Center and the Royal Ontario Museum. These are just a few examples of the diverse range of projects we've tackled over the years. We hope to have the opportunity to serve you soon and provide you with the quality and service you deserve.
                            </p>
                        </div>
                        <div className="w-full lg:w-8/12 lg:pt-8">
                            <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="/assets/logos/mcdonalds.png" alt="McDonalds featured Img" />
                                    <img className="md:hidden block" src="/assets/logos/mcdonalds.png" alt="McDonalds featured Img" />
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="/assets/logos/td-bank.png" alt="TD featured Img" />
                                    <img className="md:hidden block" src="/assets/logos/td-bank.png" alt="TD featured Img" />
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="/assets/logos/acc.png" alt="Burger King featued Img" />
                                    <img className="md:hidden block" src="/assets/logos/acc.png" alt="Burger King featued Img" />
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="/assets/logos/amc.png" alt="Elijah featured img" />
                                    <img className="md:hidden block" src="/assets/logos/amc.png" alt="Elijah featured img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
