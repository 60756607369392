import React, { useState } from "react";






export default function ContactForm() {

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [body, setBody] = useState("");
    const [email, setEmail] = useState("");


    function MessageBody() {
        if (name === "" || phone === "" || email === "" || body === "") {
            alert("Please fill out the form")
            return "";
        }
        return window.location.href = `mailto:mariacenir@viscotech.ca?subject=${"Inquiry: "}&body=${encodeURIComponent(`Name: ${name}\nPhone: ${phone}\nEmail: ${email}\nMessage: ${body}`)}`
    }

    return (
        <section className="py-6 bg-white text-gray-900">
            <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
                <div className="py-6 md:py-0 md:px-6">
                    <h1 className="text-4xl font-serif font-bold">Give us a Shout</h1>
                    <p className="pt-2 pb-4">Once you have made your choice of commercial or residential countertops, we are able to provide quality installation so that you have a countertop that is of professional grade and fit. Contact us today for a free estimate!</p>
                    <div className="space-y-4">
                    </div>
                </div>
                <div novalidate="" action="" className="container flex flex-col mx-auto space-y-12 ng-untouched ng-pristine ng-valid">
                    <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm bg-gray-50">
                        <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                            <div className="col-span-full">
                                {/* <label for="firstname" className="text-sm">First name</label> */}
                                <input id="firstname" onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                            </div>
                            <div className="col-span-full sm:col-span-3">
                                {/* <label for="lastname" className="text-sm">Phone Number</label> */}
                                <input id="phone" onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Phone Number" className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                            </div>
                            <div className="col-span-full sm:col-span-3">
                                {/* <label for="email" className="text-sm">Email</label> */}
                                <input id="email" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" className="w-full  rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                            </div>
                            <div className="col-span-full">
                                <textarea
                                    onChange={(e) => setBody(e.target.value)}
                                    id="message"
                                    name="message"
                                    placeholder="Message"
                                    className="w-full rounded-md border-none focus:ring-0   text-gray-900">
                                </textarea>
                            </div>
                            <div>
                                <button onClick={() => {MessageBody() }} className="  flex mx-auto text-white bg-custom-burgundy border-0 py-2 px-8 focus:outline-none transition ease-out hover:bg-white hover:text-custom-burgundy hover:outline hover:outline-1 hover:outline-custom-burgundy rounded text-lg">
                                    Send
                                </button>
                            </div>

                        </div>
                    </fieldset>
                </div>
            </div>
        </section>
    )
}