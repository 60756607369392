import React, { useState } from "react";






export default function QuoteForm() {

    const [subject, setSubject] = useState("Quote Request");
    const [body, setBody] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressProvince, setAddressProvince] = useState("");

    function MessageBody() {
        if (name === "" || phone === "" || email === "" || addressCity === "" || addressProvince === "" || body === "") {
            alert("Please fill out the form")
            return "";
        }
        return window.location.href = `mailto:mariacenir@viscotech.ca?subject=${"Quote Request: " + subject}&body=${encodeURIComponent(`Name: ${name}\nPhone: ${phone}\nEmail: ${email}\nAddress: ${addressCity}, ${addressProvince}\nMessage: ${body}`)}`
    }

    return (
        <section className="py-6 bg-transparent text-gray-900">
            <div className=" ml-6">
                <h1 className="text-4xl font-serif font-bold">Request a Quote</h1>
                <p className="pt-2 pb-4 pr-3 text-left">Please fill out the form below and we will contact you with a quote. Free onsite consultation available.</p>

            </div>
            <div novalidate="" action="" className="container flex flex-col mx-auto  ng-untouched ng-pristine ng-valid">

                <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md bg-transparent">
                    <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">

                        <div className="col-span-full">
                            <input
                                id="firstname"
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                placeholder="Name"
                                className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">

                            <input id="number"
                                onChange={(e) => setPhone(e.target.value)}
                                type="tel"
                                placeholder="Phone Number"
                                className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">

                            <input id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Email"
                                className="w-full  rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">

                            <input id="City"
                                onChange={(e) => setAddressCity(e.target.value)}
                                type="text"
                                placeholder="City"
                                className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">

                            <input
                                onChange={(e) => setAddressProvince(e.target.value)}
                                id="province"
                                type="text"
                                placeholder="Province"
                                className="w-full  rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full">
                            <textarea
                                onChange={(e) => setBody(e.target.value)}
                                id="message"
                                name="message"
                                placeholder="Message"
                                className="w-full rounded-md border-none focus:ring-0   text-gray-900">
                            </textarea>
                        </div>
                    </div>
                </fieldset>
                <div class=" flex flex-nowrap gap-2 ml-6">
                    <button onClick={() => { MessageBody() }}
                        className=" transition ease-in-out text-white text-lg text-left  bg-custom-burgundy border-0 py-2 px-8 focus:outline-none hover:bg-white hover:text-custom-burgundy rounded ">
                        Attach File
                    </button>
                    <button onClick={() => { MessageBody() }}
                        className="transition ease-in-out   text-white text-lg text-left  bg-custom-burgundy border-0 py-2 px-8 focus:outline-none hover:bg-white hover:text-custom-burgundy rounded ">
                        Send
                    </button>
                </div>

            </div>
        </section>
    )
}