import React from "react";
import TestimonialImages from "../helpers/FlexImageComponent";





const Testimonials = () => {
    const imgSources = [
        { key: 0, source: "/assets/logos/acc.png" },
        { key: 1, source: "/assets/logos/amc.png" },
        { key: 2, source: "/assets/logos/burger-king.png" },
        { key: 3, source: "/assets/logos/casino-rama.png" },
        { key: 4, source: "/assets/logos/mcdonalds.png" },
        { key: 5, source: "/assets/logos/pearson.jpg" },
        { key: 6, source: "/assets/logos/rom.png" },
        { key: 7, source: "/assets/logos/td-bank.png" }
    ]



    return (

        <section className="p-6 bg-gray-100 text-gray-800">
            <div className="container p-4 mx-auto text-center">
                <h2 className="text-4xl font-serif font-bold">Our Clientele</h2>
                <p class="mb-5  ml-3 md:ml-0  text-gray-600 text-center md:text-lg">
                    Our portfolio ranges from airports to hospitals, restaurants, and many more commercial & residential venues.
                </p>
            </div>
            <div className="container flex flex-wrap justify-center mx-auto text-gray-600">
                {
                    imgSources.map(images => (<TestimonialImages key={images.key} source={images.source} />))
                }

            </div>
        </section>
        // {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="fill-current w-14 h-14">
        //     <path d="M7.188 0.005l6.333 17.948v-0.010l0.5 1.411c2.786 7.88 4.281 12.104 4.286 12.109 0.005 0 0.427 0.026 0.938 0.057 1.542 0.063 3.453 0.24 4.901 0.411 0.333 0.042 0.62 0.052 0.641 0.042l-6.859-19.453-3.234-9.135c-0.609-1.734-1.13-3.208-1.156-3.266l-0.042-0.12h-6.302zM18.531 0.016l-0.016 7.073-0.010 7.078-0.583-1.646-0.75 15.745c0.74 2.089 1.135 3.203 1.141 3.208s0.427 0.036 0.932 0.057c1.547 0.068 3.453 0.24 4.906 0.417 0.333 0.036 0.625 0.052 0.641 0.036 0.021-0.010 0.026-7.224 0.026-16.010l-0.010-15.958zM7.188 0.005v15.984c0 8.792 0.010 15.995 0.021 16.005s0.552-0.042 1.208-0.115c0.651-0.083 1.557-0.177 2.010-0.214 0.693-0.068 2.76-0.203 3-0.203 0.068 0 0.073-0.359 0.083-6.771l0.010-6.776 0.505 1.417 0.177 0.5 0.76-15.734-0.255-0.729-1.214-3.365z"></path>
        // </svg> */}
    );
}

export default Testimonials;