/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




export default function Card({ logo, brand, materials, background, links }) {


    // data for the four material name
    // data for the four material logo
    // data for the Brand Name
    // data for the Brand Logo
    // data for Default background
    // data for the links 


    // console.log(cardBackground)

    // cardBackground.forEach(element => {
    //     require(element)

    // });

    const [materialBackground, SetMaterial] = useState(background)
    console.log("!~~~~~~~~~~~~")
    console.log(materialBackground)
    console.log(links)
    console.log("~~~~~~~~~~~~!")

    // for debug only


    console.log(materials)
    console.log(materials)


    function changeMaterialBackground(backgroundImage) {
        // on click change the backgroundImage
        SetMaterial(backgroundImage)
        console.log(backgroundImage)
    }



    return (

        <div className="  relative flex flex-col bg-gray-200  transition ease-in-out hover:scale-105   w-[300px] h-[425px] rounded-md shadow-md lg:w-[250px] lg:h-[375px] xl:w-[300px] xl:h-[425px]">
            <div className="">
                <div className=" h-[350px] lg:h-[300px] xl:w-[300px] xl:h-[350px] bg-black mb-1 grid grid-cols-1 rounded-md " >


                    <img className=" rounded-t-md  h-full bg-white object-fill transition ease-in-out" src={materialBackground} alt={brand}></img>


                    <div className=" absolute inset-x-0 bottom-20   justify-center  content-end flex  flex-wrap gap-1">

                        {
                            materials.map(cardBG => (
                                <div>
                                    <button type="button" onClick={() => changeMaterialBackground(cardBG)} className={`w-5 h-5 rounded-full flex items-end text-left mx-1  font-semibold tracking-wide bg-custom-burgundy text-gray-50 transition ease-in-out hover:scale-110 hover:bg-white `}>

                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <h1 className="ml-2 mb-2 text-2xl font-semibold sm:text-2xl title-font text-gray-800">{brand}</h1>
            <button className="ml-2 inline-flex items-center space-x-2 text-sm text-custom-burgundy transition ease-in-out hover:text-red-600" onClick={() => window.open(links, '_blank', 'noreferrer')}>
                <span>Learn More</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
            </button>

        </div>
    )
}



/* <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md bg-red-600 text-gray-50">Read more</button> *///}
/**            <div className="  relative bg-[url('../public/assets/images/test.jpg')] bg-cover bg-center bg-no-repeat rounded-lg">
                <div className="   h-64 ">
                    {/* <h2 className=" text-3xl text-center text-white font-semibold tracking-wide bg-black/25 sm:bg-transparent sm:bg-gradient-to-r sm:from-black/25 sm:to-white/0">Brand Name</h2> *///}

                   // </div>
                   // </div> */







