import React from "react";







export default function Steps() {
    return (
        <section className="p-6 bg-white-100 text-gray-800">
            <div className="container mx-auto">
                <h2 className="text-5xl font-bold font-serif text-center text-gray-900">Our Process</h2>
                <span className="block mt-2 mb-2 text-xs font-medium tracking-widest text-center uppercase text-custom-burgundy">We believe it’s vital to partner with quality manufacturers. We are an authorized Dupont dealer and many more…</span>
                <div className="grid gap-6 my-16 lg:grid-cols-3">
                    <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-50">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-custom-burgundy text-gray-50">1</div>
                        <p className="text-2xl font-thin">
                            <b>Free Estimate.</b> Fax or email us a drawing of your countertop layout, with back wall measurements, material, colour, cut-outs that will be involved, sink information, indicate if there will be backsplash, and what kind of edge you will like, we will give you a free estimate.
                        </p>
                    </div>
                    <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-50">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-custom-burgundy text-gray-50">2</div>
                        <p className="text-2xl font-thin">
                            <b>Templating.</b> We take a physical measurement of your existing cabinets in order for us to fabricate your tops right.
                        </p>
                    </div>
                    <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-50">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-custom-burgundy text-gray-50">3</div>
                        <p className="text-2xl font-thin">
                            <b>Fabrication.</b> Templates translate into production. We oversee the process to make sure everything goes as smoothly as possible.
                        </p>
                    </div>
                    <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-50">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-custom-burgundy text-gray-50">4</div>
                        <p className="text-2xl font-thin">
                            <b>Installation.</b> Normal business lead time is 5-7 days for acrylic material. Peek season 10-12 days. Other material such as granite & engineer stone will take 10-12 days from template time.
                        </p>
                    </div>
                    <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-50">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-custom-burgundy text-gray-50">5</div>
                        <p className="text-2xl font-thin">
                            <b>Excitement.</b> A very happy customer!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}