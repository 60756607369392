import React from 'react';
import { NavLink } from 'react-router-dom';

const Hero = () => {

    function displayWithWhiteGradient() {
        return (
            <section class="relative bg-[url('../public/assets/images/test.jpg')] bg-cover bg-center bg-no-repeat">
                <div
                    class="absolute inset-0 bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"
                ></div>

                <div
                    class="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
                >
                    <div class="max-w-xl text-center sm:text-left">
                        <h1 class="text-3xl font-extrabold font-serif sm:text-5xl">
                            Elevating Kitchens for 40 Years with Stylish, Durable, and

                            <strong class="block font-extrabold text-custom-burgundy">
                                Quality Countertop Solutions
                            </strong>
                        </h1>

                        <p class="mt-4 max-w-lg sm:text-xl sm:leading-relaxed">
                            Expertly Designed Countertops - 40 Years of Service - At VisCotech, Your one-stop-shop for Countertop Solutions
                        </p>

                        <div class="mt-8 flex flex-wrap gap-4 text-center">

                            <NavLink to="/Contact" className="block w-full rounded bg-custom-burgundy px-12 py-3 text-sm font-medium text-white shadow transition ease-out hover:bg-white hover:text-custom-burgundy hover:outline hover:outline-1 hover:outline-custom-burgundy active:bg-rose-500 sm:w-auto"
                            >
                                Request A Quote
                            </NavLink>


                            <NavLink to="/Product" className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-custom-burgundy shadow transition ease-out hover:bg-custom-burgundy hover:text-white hover:outline hover:outline-1 hover:outline-white focus:outline-none focus:ring active:text-rose-500 sm:w-auto">
                                Learn More
                            </NavLink>
                        </div>

                    </div>
                    {/* Image Test */}
                    <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                        {/* <img src="/assets/images/test.jpg" alt="" className="object-contain h-72 drop-shadow-md sm:h-80 lg:h-96 xl:h-112 2xl:h-128" /> */}
                    </div>
                </div>
            </section>
        );

    }

    return displayWithWhiteGradient();
};

export default Hero;








