import React from "react";
import { NavLink } from "react-router-dom";






export default function Footer() {

	function MessageBody() {

		return window.location.href = `mailto:mariacenir@viscotech.ca?subject=${"Inquiry" }`
	}

	return (
		<footer className="px-4 divide-y bg-gray-100 text-gray-800 ">
			<div className="container flex flex-col justify-between py-10   mx-auto space-y-8 lg:ml-[400px] lg:flex-row lg:space-y-0">
				<div className="lg:w-1/3">
					<a rel="noopener noreferrer" href="viscotech.ca" className="flex justify-center space-x-3 lg:justify-start">
						<img src="/assets/imagePacks/VCLogoBurgundy.png" className=" w-52" alt="Viscotech" />
					</a>
				</div>
				<div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4 ml-10">
					<div className="space-y-3">
						<h3 className="tracking-wide uppercase text-gray-900">Get in Touch</h3>
						<ul className="space-y-1">
							<li>
								<p>Sales & Inquiry</p>
								<p>Maria Cenir</p>
							</li>
							<li>
								<p>Main Phone</p>
								<p className=' text-left text-xl text-custom-burgundy '>905.794.1122</p>

							</li>
							<li>
								<p>Fax</p>
								<p>905.794.1177</p>
							</li>
							<li>
								<p>Email</p>
								<button onClick={() =>MessageBody()}> <p className=' text-left text-xl text-custom-burgundy  transition ease-in-out hover:underline hover:text-custom-burgundy"'>mariacenir@viscotech.ca</p> </button>
							</li>
						</ul>
					</div>
					<div className="space-y-3">
						<h3 className="tracking-wide uppercase text-gray-900">Site Map</h3>
						<ul className="space-y-1">
							<li>
								<NavLink to="/" className={'transition ease-in-out hover:text-custom-burgundy'}>Home</NavLink>
							</li>
							<li>
								<NavLink to="/Product" className={'transition ease-in-out hover:text-custom-burgundy'}>Our Products</NavLink>
							</li>
							<li>
								<NavLink to="/Gallery" className={'transition ease-in-out hover:text-custom-burgundy'}>Gallery</NavLink>
							</li>
							<li>
								<NavLink to="/About" className={'transition ease-in-out hover:text-custom-burgundy'}>About Us</NavLink>
								{/* <button>About Us</button> */}
							</li>
							<li>
								<NavLink to="/Contact" className={'transition ease-in-out hover:text-custom-burgundy'}>Contact Us</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="py-6 text-sm text-center text-gray-600">© 2023  VisCotech International Industries. All Rights Reserved.</div>
		</footer>
	)
}