import React, { useState } from "react";






export default function InquiryForm() {

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [body, setBody] = useState("");
    const [email, setEmail] = useState("");


    function MessageBody() {
        if (name === "" || phone === "" || email === ""  || body === "") {
            alert("Please fill out the form")
            return "";
        }
        return window.location.href = `mailto:mariacenir@viscotech.ca?subject=${"Inquiry: "}&body=${encodeURIComponent(`Name: ${name}\nPhone: ${phone}\nEmail: ${email}\nMessage: ${body}`)}`
    }

    return (
        <section className="py-6 bg-transparent text-gray-900">
            <div className=" ml-6">
                <h1 className="text-4xl font-serif font-bold">Give us a Shout</h1>
                <p className="pt-2 pb-4 pr-3 text-left">Once you have made your choice of commercial or residential countertops, we are able to provide quality installation so that you have a countertop that is of professional grade and fit. Contact us today for a free estimate!</p>

            </div>
            <div novalidate="" action="" className="container flex flex-col mx-auto space-y-12 ng-untouched ng-pristine ng-valid">

                <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md  bg-transparent">

                    <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">

                        <div className="col-span-full">
                            {/* <label for="firstname" className="text-sm">First name</label> */}
                            <input id="firstname" type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">
                            {/* <label for="lastname" className="text-sm">Phone Number</label> */}
                            <input id="lastname" type="tel" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} className="w-full rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full sm:col-span-3">
                            {/* <label for="email" className="text-sm">Email</label> */}
                            <input id="email" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="w-full  rounded-md border-none focus:ring-0 border-gray-300 text-gray-900" />
                        </div>
                        <div className="col-span-full">
                            <textarea
                                onChange={(e) => setBody(e.target.value)}
                                id="message"
                                name="message"
                                placeholder="Message"
                                className="w-full rounded-md border-none focus:ring-0   text-gray-900">
                            </textarea>
                        </div>
                        <div>
                            <button onClick={() => { MessageBody() }} className=" flex mx-auto text-white bg-custom-burgundy border-0 py-2 px-8 focus:outline-none transition ease-in-out hover:bg-white hover:text-custom-burgundy rounded text-lg">
                                Send
                            </button>
                        </div>

                    </div>
                </fieldset>
            </div>
        </section>
    )
}