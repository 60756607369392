import { useState } from "react";
import {  NavLink } from "react-router-dom";

export default function NavBar({ home, fields, projects, contacts }) {
    const [navbar, setNavbar] = useState(false);
    const nameLogo = "VisCoTech";



    return (
        <div>

            <nav className="z-50 w-full bg-custom-burgundy shadow fixed">
                <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                    <div>
                        <div className="flex items-center justify-between py-3 md:py-2 md:block">
                            <button>
                                <div>
                                    <img onClick={home} src="/assets/imagePacks/VCLogoNoBottom200White.png" className=" w-52" />
                                </div>
                            </button>
                            {/* <button> <h2 onClick={home} className="text-2xl text-white font-bold">{nameLogo}</h2></button> */}
                            <div className="md:hidden">
                                <button
                                    className="p-2 text-white-700 rounded-md outline-none focus:border-cyan-800 focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            viewBox="0 0 20 20"
                                            fill="white"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="white"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"}`}>
                            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                                <li onClick={home} className="text-white hover:text-red-500">
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li onClick={fields} className="text-white hover:text-red-500">
                                    <NavLink to="/Product">Our Products</NavLink>
                                    {/* <button>Our Products</button> */}
                                </li>
                                <li onClick={contacts} className="text-white hover:text-red-500">
                                    <NavLink to="/Gallery">Gallery</NavLink>
                                </li>
                                <li onClick={contacts} className="text-white hover:text-red-500">
                                    <NavLink to="/About">About Us</NavLink>
                                    {/* <button>About Us</button> */}
                                </li>
                                <li onClick={contacts} className="text-white hover:text-red-500">
                                    <NavLink to="/Contact">Contact Us</NavLink>
                                </li>
                                {/* Open Mail to??? */}
                                <NavLink to="/Contact" className="bg-white text-custom-burgundy active:bg-white hover:bg-custom-burgundy hover:text-white hover:outline hover:outline-1 hover:outline-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                                >                                Request A Quote
                                </NavLink>
                            </ul>

                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}