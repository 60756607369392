import '../App.css';
import { IoLocationSharp } from 'react-icons/io5'
import { FaFax } from 'react-icons/fa'
import { AiTwotonePhone } from 'react-icons/ai'
import { BsBriefcaseFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { IconContext } from 'react-icons';
import QuoteForm from '../components/QuoteFormComponent';
import InquiryForm from '../components/InquiryFormComponent';

function Contact() {
    document.title = "Contact Us"
    function MessageBody() {

        return window.location.href = `mailto:mariacenir@viscotech.ca?subject=${"Inquiry"}`
    }
    return (
        <div className='pt-20'>
            <section className="py-6 bg-gray-100 text-gray-900">

                <div className=" grid max-w-7xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-3 md:divide-x">
                    {/* Turn this to a grid 3 */}
                    <div className="py-6 md:py-0 md:px-6">
                        <h1 className="text-4xl font-serif font-bold">Contact Us</h1>
                        <p className="pt-2 pb-4"> </p>
                        <div className="space-y-1">
                            <div className='pb-3'>
                                <div className=' flex justify-start align  '>
                                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                                        <IoLocationSharp className='' />
                                    </IconContext.Provider>

                                    <p className=' ml-2 text-2xl font-semibold'>Address</p>
                                </div>
                                <p className=' text-left'>3 Brewster Road Unit 16
                                    Brampton, ON L6T 5G9</p>
                            </div>
                            <div className='pb-3'>
                                <div className=' flex justify-start  '>
                                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                                        <BsBriefcaseFill className='' />
                                    </IconContext.Provider>

                                    <p className=' ml-2 text-2xl font-semibold'>Sales & Inquiry</p>
                                </div>
                                <p className=' text-left'>Maria Cenir</p>
                            </div>
                            <div className='pb-3'>
                                <div className=' flex justify-start  '>
                                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                                        <AiTwotonePhone className='' />
                                    </IconContext.Provider>

                                    <p className=' ml-2 text-2xl font-semibold'>Main Phone</p>
                                </div>
                                <p className=' text-left text-xl text-custom-burgundy  "'>905.794.1122</p>
                            </div>
                            <div className='pb-3'>
                                <div className=' flex justify-start  '>
                                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                                        <FaFax className='' />
                                    </IconContext.Provider>

                                    <p className=' ml-2 text-2xl font-semibold'>Fax</p>
                                </div>
                                <p className=' text-left'>05.794.1177</p>
                            </div>
                            <div className='pb-3'>
                                <div className=' flex justify-start  '>
                                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                                        <MdEmail className='' />
                                    </IconContext.Provider>

                                    <p className=' ml-2 text-2xl font-semibold'>Email</p>
                                </div>
                                <button onClick={() =>MessageBody()}> <p className=' text-left text-xl text-custom-burgundy  transition ease-in-out hover:underline hover:text-custom-burgundy"'>mariacenir@viscotech.ca</p> </button>

                            </div>
                        </div>
                    </div>
                    <InquiryForm />
                    <QuoteForm />
                </div>
            </section>
        </div>
    );
}

export default Contact;
