import { useEffect } from 'react';
import '../App.css';
import MainGallery from '../components/MainGallery';


function GalleryPage() {
    const isTrue = true;
    return (
        <div className='pt-10'>
            <MainGallery />
        </div>
    )
}

export default GalleryPage;
