import React from "react";
import { NavLink } from "react-router-dom";





export default function MainFeature() {
    return (
        <section class="px-4 py-24 mx-auto max-w-7xl">
            <div class=" grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
                <div>
                    <h2 class="mb-4 ml-3 md:ml-0  text-2xl font-bold font-serif tracking-tight text-left text-black md:leading-tight md:text-4xl">Upgrade Your Kitchen with a Free Sink</h2>
                    <p class="mb-5  ml-3 md:ml-0 text-left text-base text-gray-600 sm:text-left md:text-lg">
                    When you choose us to install your new countertop, you'll get even more value for your money. We're currently offering a free sink with any countertop material purchase. Upgrade your kitchen and get more functionality without the extra cost. 
                    </p>

                    <NavLink to="/Contact" className="md:ml-0 md:items-center px-8 py-3 m-2 text-lg font-semibold rounded bg-custom-burgundy text-gray-50  transition ease-out hover:bg-white hover:text-custom-burgundy hover:outline hover:outline-1 hover:outline-custom-burgundy ">Learn More</NavLink>
                </div>
                <div class="w-full h-full shadow-lg  py-48 rounded-xl bg-gray-200 bg-[url('../public/assets/images/Poliform-Varenna_foto-8-0013ok-RID2-copy.jpg')] bg-cover bg-center bg-no-repeat"></div>
            </div>
            <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
                <div class="order-none md:order-2">
                    <h2 class="mb-4 ml-3  text-2xl font-extrabold font-serif tracking-tight text-left text-black md:leading-tight sm:text-left md:text-4xl">Partnering with Quality Manufacturers</h2>
                    <p class="mb-5  ml-3 text-base text-left text-gray-600 sm:text-left md:text-lg ">
                        We believe it's vital to partner with quality manufacturers to ensure that we can provide our customers with the best possible products. We are an authorized Dupont fabricator and have partnerships with many more reputable manufacturers in the industry.
                    </p>
                    <NavLink to="/Product" className="px-8 py-3 m-2 text-lg font-semibold rounded bg-custom-burgundy text-gray-50  transition ease-out hover:bg-white hover:text-custom-burgundy hover:outline hover:outline-1 hover:outline-custom-burgundy ">Learn More</NavLink>
                </div>
                <div class="w-full h-full py-48 bg-transparent bg-[url('../public/assets/images/products.png')] bg-contain bg-center bg-no-repeat"></div>
            </div>
        </section>

    )
}
