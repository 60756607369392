import React from "react";
import { NavLink } from "react-router-dom";
import MainFeature from "./MainFeatureComponent";
import { backgroundImages } from "../util/imageImporter";





export default function Gallery() {

    let generatedNumbers = [];

    function generateUniqueRandomNumber(n) {
        let randomNumbers = [];
        for (let i = 0; i < n; i++) {
            let randomNumber = Math.floor(Math.random() * 11);
            if (generatedNumbers.indexOf(randomNumber) === -1) {
                randomNumbers.push(randomNumber);
                generatedNumbers.push(randomNumber);
            } else {
                i--
            }
        }
        return randomNumbers;
    }

    generateUniqueRandomNumber(4);

    return (
        <section className="bg-gray-100 text-gray-800">
            <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                    <h2 className="text-3xl font-semibold font-serif tracking-tight text-center sm:text-5xl text-gray-900">Bringing Your Vision to Life</h2>
                    <p className="max-w-3xl mx-auto mt-4 mb-2 text-xl text-center text-gray-600">Explore the possibilities with our gallery of completed designs</p>

                    <section className="py-6 bg-gray-100">
                        <div className="container flex flex-col justify-center p-4 mx-auto">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                                <img className="object-cover w-full bg-gray-500 aspect-square" src={backgroundImages[generatedNumbers[0]]} alt="Gallery 0" />
                                <img className="object-cover w-full bg-gray-500 aspect-square" src={backgroundImages[generatedNumbers[1]]} alt="Gallery 1" />
                                <img className="object-cover w-full bg-gray-500 aspect-square" src={backgroundImages[generatedNumbers[2]]} alt="Gallery 2" />
                                <img className="object-cover w-full bg-gray-500 aspect-square" src={backgroundImages[generatedNumbers[3]]} alt="Gallery 3" />
                            </div>
                        </div>
                    </section>
                    <div className="flex flex-wrap justify-center">
                        <NavLink to="/Gallery" className="px-8 py-3 m-2 text-lg font-semibold rounded transition ease-in-out hover:bg-white hover:text-custom-burgundy hover:outline hover:outline-1 hover:outline-custom-burgundy bg-custom-burgundy text-gray-50">See Gallery</NavLink>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </section>
    )
}