import corianImage0 from '../assets/images/products/Corian/0.jpg'
import corianImage1 from '../assets/images/products/Corian/1.jpg'
import corianImage2 from '../assets/images/products/Corian/2.jpg'
import corianImage3 from '../assets/images/products/Corian/3.jpg'

import CaesarstoneImage0 from '../assets/images/products/Caesarstone/0.jpg'
import CaesarstoneImage1 from '../assets/images/products/Caesarstone/1.jpg'
import CaesarstoneImage2 from '../assets/images/products/Caesarstone/2.jpg'
import CaesarstoneImage3 from '../assets/images/products/Caesarstone/3.jpg'

import formicaImage0 from '../assets/images/products/Formica/0.jpg'
import formicaImage1 from '../assets/images/products/Formica/1.jpg'
import formicaImage2 from '../assets/images/products/Formica/2.jpg'
import formicaImage3 from '../assets/images/products/Formica/3.jpg'


import hanexImage0 from '../assets/images/products/hanex/0.jpg'
import hanexImage1 from '../assets/images/products/hanex/1.jpg'
import hanexImage2 from '../assets/images/products/hanex/2.jpg'
import hanexImage3 from '../assets/images/products/hanex/3.jpg'

import hanstoneImage0 from '../assets/images/products/hanstone/0.webp'
import hanstoneImage1 from '../assets/images/products/hanstone/1.webp'
import hanstoneImage2 from '../assets/images/products/hanstone/2.webp'
import hanstoneImage3 from '../assets/images/products/hanstone/3.webp'

import himacsImage0 from '../assets/images/products/himacs/0.jpg'
import himacsImage1 from '../assets/images/products/himacs/1.jpg'
import himacsImage2 from '../assets/images/products/himacs/2.jpg'
import himacsImage3 from '../assets/images/products/himacs/3.jpg'

import kstoneImage0 from '../assets/images/products/Kstone/0.jpg'
import kstoneImage1 from '../assets/images/products/Kstone/1.jpg'
import kstoneImage2 from '../assets/images/products/Kstone/2.jpg'
import kstoneImage3 from '../assets/images/products/Kstone/3.jpg'

import meganiteImage0 from '../assets/images/products/Meganite/0.jpg'
import meganiteImage1 from '../assets/images/products/Meganite/1.jpg'
import meganiteImage2 from '../assets/images/products/Meganite/2.jpg'
import meganiteImage3 from '../assets/images/products/Meganite/3.jpg'

import silestoneImage0 from '../assets/images/products/Silestone/0.jpg'
import silestoneImage1 from '../assets/images/products/Silestone/1.jpg'
import silestoneImage2 from '../assets/images/products/Silestone/2.jpg'
import silestoneImage3 from '../assets/images/products/Silestone/3.jpg'

import staronImage0 from '../assets/images/products/Staron/0.jpg'
import staronImage1 from '../assets/images/products/Staron/1.jpg'
import staronImage2 from '../assets/images/products/Staron/2.jpg'
import staronImage3 from '../assets/images/products/Staron/3.jpg'

import tcestoneImage0 from '../assets/images/products/TCESTONE/0.jpg'
import tcestoneImage1 from '../assets/images/products/TCESTONE/1.jpg'
import tcestoneImage2 from '../assets/images/products/TCESTONE/2.jpg'
import tcestoneImage3 from '../assets/images/products/TCESTONE/3.jpg'

import viateraImage0 from '../assets/images/products/Viatera/0.jpg'
import viateraImage1 from '../assets/images/products/Viatera/1.jpg'
import viateraImage2 from '../assets/images/products/Viatera/2.jpg'
import viateraImage3 from '../assets/images/products/Viatera/3.jpg'

import WilsonartImage0 from '../assets/images/products/Wilsonart/0.webp'
import WilsonartImage1 from '../assets/images/products/Wilsonart/1.webp'
import WilsonartImage2 from '../assets/images/products/Wilsonart/2.webp'
import WilsonartImage3 from '../assets/images/products/Wilsonart/3.webp'

import backgroundImage0 from '../assets/images/productsBackground/0.png'
import backgroundImage1 from '../assets/images/productsBackground/1.jpg'
import backgroundImage2 from '../assets/images/productsBackground/2.webp'
import backgroundImage3 from '../assets/images/productsBackground/3.jpg'
import backgroundImage4 from '../assets/images/productsBackground/4.jpg'
import backgroundImage5 from '../assets/images/productsBackground/5.webp'
import backgroundImage6 from '../assets/images/productsBackground/6.jpg'
import backgroundImage7 from '../assets/images/productsBackground/7.jpg'
import backgroundImage8 from '../assets/images/productsBackground/8.webp'
import backgroundImage10 from '../assets/images/productsBackground/10.jpg'
import backgroundImage11 from '../assets/images/productsBackground/11.jpg'
import backgroundImage12 from '../assets/images/productsBackground/12.jpg'



export const backgroundImages =
    [
        backgroundImage0,
        backgroundImage1,
        backgroundImage2,
        backgroundImage3,
        backgroundImage4,
        backgroundImage5,
        backgroundImage6,
        backgroundImage7,
        backgroundImage8,
        backgroundImage10,
        backgroundImage11,
        backgroundImage12,
    ]




export const corianImages =
    [
        corianImage0,
        corianImage1,
        corianImage2,
        corianImage3
    ]
export const caesarstoneImages =
    [
        CaesarstoneImage0,
        CaesarstoneImage1,
        CaesarstoneImage2,
        CaesarstoneImage3
    ]
export const formicaImages =
    [
        formicaImage0,
        formicaImage1,
        formicaImage2,
        formicaImage3
    ]
export const hanexImages =
    [
        hanexImage0,
        hanexImage1,
        hanexImage2,
        hanexImage3
    ]
export const hanstoneImages =
    [
        hanstoneImage0,
        hanstoneImage1,
        hanstoneImage2,
        hanstoneImage3
    ]
export const himacsImages =
    [
        himacsImage0,
        himacsImage1,
        himacsImage2,
        himacsImage3
    ]
export const kstoneImages =
    [
        kstoneImage0,
        kstoneImage1,
        kstoneImage2,
        kstoneImage3
    ]
export const meganiteImages =
    [
        meganiteImage0,
        meganiteImage1,
        meganiteImage2,
        meganiteImage3
    ]
export const silestoneImages =
    [
        silestoneImage0,
        silestoneImage1,
        silestoneImage2,
        silestoneImage3
    ]
export const staronImages =
    [
        staronImage0,
        staronImage1,
        staronImage2,
        staronImage3
    ]
export const tcestoneImages =
    [
        tcestoneImage0,
        tcestoneImage1,
        tcestoneImage2,
        tcestoneImage3
    ]
export const viateraImages =
    [
        viateraImage0,
        viateraImage1,
        viateraImage2,
        viateraImage3
    ]
export const wilsonartImages =
    [
        WilsonartImage0,
        WilsonartImage1,
        WilsonartImage2,
        WilsonartImage3
    ]



// Temporary solution for importing image, 
