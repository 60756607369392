import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Card from "../components/CardComponent";
import CardCarousel from "../components/CustomCarouselComponent";
import staronBG from '../assets/images/staron-384x250.jpg'
import { corianImages, caesarstoneImages, formicaImages, hanexImages, hanstoneImages, himacsImages, kstoneImages, meganiteImages, silestoneImages, staronImages, tcestoneImages, viateraImages, wilsonartImages } from "../util/imageImporter";
import { backgroundImages } from "../util/imageImporter";




export default function ProductPage() {
    document.title = "Products"



    var cardDetails = [{
        logo: "",
        brand: "Corian",
        materials: corianImages,
        background: backgroundImages[0],
        links: "https://www.na.corian.com/"
    },
    {
        logo: "",
        brand: " Formica",
        materials: formicaImages,
        background: backgroundImages[2],
        links: "https://www.formica.com/en-us"
    },
    {
        logo: "",
        brand: "Hi-macs",
        materials: himacsImages,
        background: backgroundImages[10],
        links: "https://www.lxhausys.com/us/index"
    },
    {
        logo: "",
        brand: "Caesarstone",
        materials: caesarstoneImages,
        background: backgroundImages[1],
        links: "https://www.caesarstone.ca/catalogue/"
    }, {
        logo: "",
        brand: " KStone",
        materials: kstoneImages,
        background: backgroundImages[3],
        links: "https://kstonequartz.com/"
    }, {
        logo: "",
        brand: " Silestone",
        materials: silestoneImages,
        background: backgroundImages[4],
        links: "https://www.cosentino.com/en-ca/colours/silestone/"
    },  {
        logo: "",
        brand: "Staron",
        materials: staronImages,
        background: backgroundImages[6],
        links: "https://www.staron.com/intro/us"
    },  {
        logo: "",
        brand: "Meganite",
        materials: meganiteImages,
        background: backgroundImages[8],
        links: "https://www.meganite.com/en/na"
    },  {
        logo: "",
        brand: "Wilsonart",
        materials: wilsonartImages,
        background: backgroundImages[11],
        links: "https://www.wilsonart.com/"
    }, {
        logo: "",
        brand: "TCE Stone",
        materials: tcestoneImages,
        background: backgroundImages[5],
        links: "http://tcestone.com/"
    }]

    function cardTest() {
        console.log(cardDetails)
    }
    cardTest();

    return (



        <section className=" py-24 bg-gray-50 text-gray-800 lg:align-middle">
            <div className="container flex flex-col md:flex-row mx-auto lg:flex-row">
                <div className="m-3 w-96 mr-10 ">
                    <div>
                        <h2 className=" text-3xl font-bold font-serif md:text-4xl">Our Products</h2>
                        <span class="flex flex-1 w-[100px] p-0.5 mb-10 bg-custom-burgundy"></span>
                    </div>
                    <div>
                        <p className="pb-8 font-bold ">Viscotech can help you create the perfect design for your space, from stylish kitchens to luxurious bathrooms.</p>
                    </div>
                    <div>
                        <p className="pb-8  ">With hundreds of colours, numerous distinct finishes to choose from, and our professional installation service, you can rest assured that your investments will be enjoyed for years to come.</p>
                    </div>
                    <div>
                        <p className="pb-8 text-custom-burgundy">We repair existing solid surface counter tops, restoring their lustre to make them look all brand new.</p>
                    </div>
                    <div>
                        <NavLink to="/Contact">  <p className="underline transition ease-in-out hover:text-custom-burgundy">Call us today for details!</p> </NavLink>
                    </div>
                </div>
                <div>

                </div>

                <div className="  w-full grid justify-items-center    gap-5 grid-cols-1 my-24  lg:mx-28  md:grid-cols-1  laptop:grid-cols-2  2xl:grid-cols-3">
                    <div className="flex col-span-1 lg:col-span-2 2xl:col-span-3 overflow-hidden rounded-md shadow-sm lg:flex-row">
                        <section className="bg-[url('../public/assets/images/dupont-corian-1024x270.jpg')] bg-cover  bg-gray-100 text-gray-100">
                            <div className="container mx-auto flex flex-col justify-center p-4 space-y-8 md:p-10 lg:space-y-0 lg:space-x-12 lg:justify-between lg:flex-row">
                                <div className="flex flex-col space-y-4 text-center lg:text-left">
                                    <h1 className="text-5xl font-serif font-bold leading-none">Beautiful Spaces Start with Corian® Design</h1>
                                    {/* <p className="text-lg">Doloribus consectetur quasi ipsa quo neque culpa blanditiis ducimus recusandae a veritatis optio cumque, in harum ad nam!</p> */}
                                </div>
                                <div className="flex flex-row items-center self-center justify-center flex-shrink-0 shadow-md lg:justify-end">
                                    <div className="flex flex-row">
                                        <button type="button" onClick={() => window.open("https://www.na.corian.com/", '_blank', 'noreferrer')} className="w-full p-3 font-semibold  rounded-md   bg-custom-burgundy text-white transition ease-in-out hover:text-custom-burgundy hover:bg-white hover:scale-105">Learn More</button>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    {
                        cardDetails.map(cardDetail => (
                            <Card key={cardDetail.brand} brand={cardDetail.brand} background={cardDetail.background} materials={cardDetail.materials} links={cardDetail.links} />
                        ))
                    }

                </div>
            </div>
        </section>





















        // <section className="bg-gray-100 text-gray-800 pt-10">
        //     <div className="container max-w-xl   py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl ">
        //         <div className=" ">
        //             <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl text-gray-900">Stylish & Durable Countertop Solutions</h2>
        //             <p className="max-w-3xl mx-auto mt-4 mb-2 text-xl text-center text-gray-600">At VisCotech we strive to give you the quality & service in the solid surface industry.</p>
        //             <Products />

        //             <section className="py-6 bg-gray-100 pl-4 ">
        //                 <div></div>
        //                 <div className="grid grid-cols-1 gap-1  sm:flex-wrap md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        //                     {/* <div className="flex gap-1 flex-unwrap"> */}



        //                     <Card />
        //                     <Card />
        //                     <Card />
        //                     <Card />
        //                     <Card />
        //                     <Card />
        //                     <Card />


        //                 </div>
        //             </section>
        //             <div className="flex flex-wrap justify-center">
        //                 {/* <button className="px-8 py-3 m-2 text-lg font-semibold rounded bg-custom-burgundy text-gray-50">See Gallery</button> */}
        //             </div>
        //         </div>
        //         <div>
        //         </div>
        //     </div>
        // </section>
    )
}