import '../App.css';
import ContactForm from '../components/ContactFormComponent';
import Hero from '../components/HeroComponent';
import Steps from '../components/StepsComponent';
import Testimonials from '../components/TestimonialsComponent';
import Gallery from '../components/GalleryComponent';
import MainFeature from '../components/MainFeatureComponent';

function Home() {
  document.title = "Home"
  return (
    <div>
      <Hero />
      <MainFeature />
      <Gallery />
      <Steps />
      <Testimonials />
      <ContactForm />
    </div>
  );
}

export default Home;
