import React from "react";






const TestimonialImages = ({ source }) => {
    return (
        
         <div className=" flex justify-center w-1/2 p-2 align-middle md:w-1/4 xl:w-1/4">
       {/* <div className=" flex justify-center w-1/2 p-2 align-middle md:w-52 xl:w-44"> */}
            {/* <img src={source} alt="" className=" m-2 object-contain  drop-shadow-md h-36 lg:h-4/5" /> */}
            {/* <img src={source} alt="" className="  object-contain w-2/4 h-2/4 drop-shadow-md md:w-3/4 md:h-3/4 lg:h-4/5 xl:h-112 2xl:h-128" /> */}
            {/* <img src={source} alt="" className="object-contain w-8/12 h-8/12 drop-shadow-md sm:h-80 lg:h-4/5 xl:h-112 2xl:h-128" /> */}
            <img src={source} alt="" className="object-contain drop-shadow-md w-72  h-2/3 lg:w-2/3 lg:h-36 xl:w-3/4 xl:h-36  " />
        </div>

    );
}



export default TestimonialImages